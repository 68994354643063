<script>
export default {
	name: 'EnrichPortalDefaultLayout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import EnrichOverlays from '~/components/EnrichPortal/EnrichOverlays.vue';

</script>


<template>
<div class="EnrichPortalDefaultLayout flex flex-col min-h-screen" data-layout="EnrichPortalDefault">
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/enrich-portal/$country/$lang/site/header/master"
	/>
	<div id="content"></div>
	<div id="booking-subpage-container"></div>
	
	<!-- <div class="enrich-default-grid-container">
		<div class="side-nav-bar-wrapper">
			<ExperienceFragment
				class="global-enrich-navigation-bar"
				xfPath="/content/experience-fragments/enrich-portal/$country/$lang/site/enrich-navigation-bar/master"
			/>
		</div>
		<aside class="side-content-wrapper">
		</aside>
		<main class="main-content-wrapper">
			<slot></slot>
		</main>
	</div> -->
	<main class="layout-container-center">
		<slot></slot>
	</main>
	<EnrichOverlays />
	
	<div class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>

	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/cookies-notification/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.EnrichPortalDefaultLayout {
	background-color: #f0f3f5;
	background-image: url('~/assets/enrich-songket-repeatable-x.png');
	background-repeat: repeat-x;
	background-position: center 555px;

	.layout-container-center {
		@media #{var.$query-min-2xl} {
			max-width: 1325px;
		}
	}

	.layout-container-center {
		@media #{var.$query-min-xl} {
			padding-top: 35px;
		}
	}
}

:slotted([data-component="AEMPage"]) {
	@apply mb-10;
	
	> .aem-page > [data-component="ResponsiveGrid"] > .aem-container > [data-component="ResponsiveGrid"] .aem-container > [data-component="MHLayoutContainer"] > .aem-container > div {
		// min-height: 400px;
		display: grid;
		column-gap: 1.75rem;
		row-gap: 1rem;
		grid-template-columns: 315px 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'side-nav-bar main-content'
			'side-content main-content';
			
		> [data-component="ExperienceFragment"] {
			grid-area: side-nav-bar;
		}
		> [data-component="MHLayoutContainer"]:nth-of-type(2) {
			grid-area: side-content;
			// background-color: gold;
			height: max-content;
		}
		> [data-component="MHLayoutContainer"]:nth-of-type(3) {
			grid-area: main-content;
			// background-color: salmon;
		}
		> [data-component="MHLayoutContainer"]:has(.EnrichCrossSellTiles) {
			grid-area: main-content;
		}
		// This change will effect mobile view of enrich crosssell tiles for content page layout
		> [data-component="MHLayoutContainer"]:has(.EnrichCrossSellTiles),
		> [data-component="MHLayoutContainer"]:has(.EnrichNavigationBar) {
			max-width: 900px;
			overflow: hidden;

			@media #{var.$query-max-xs} {
				max-width: 450px;
			}

			@media #{var.$query-max-md}{
				max-width: 600px;
			}

			@media #{var.$query-min-md} and #{var.$query-max-lg} {
				max-width: 700px;
			}

			@media #{var.$query-min-lg} and #{var.$query-max-xl} {
				max-width: 760px;
			}
		}
		
		> .cq-Editable-dom.new {
			grid-column: span 2;
		}
		
		@media #{var.$query-max-md} {
			grid-template-columns: 1fr;
			grid-template-rows: none;
			grid-template-areas:
				'side-nav-bar'
				'main-content'
				'side-content';
			
			> [data-component="MHLayoutContainer"]:nth-of-type(2) {
				grid-area: main-content;
			}

			> [data-component="MHLayoutContainer"]:has(.EnrichCrossSellTiles) {
				grid-area: unset;
			}
		}
	}
}

</style>
