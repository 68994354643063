<script>
export default {
	name: 'OverlayDrawer',
	inheritAttrs: false,
};
</script>

<script setup>
import useDisableScroll from '~composables/useDisableScroll.js';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';
const { isRTL } = useIsRTL();

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	backdropClosable: { type: Boolean, default: true },
	isEnrichNavbarOverlay: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:isVisible',
]);

const rootEl = ref(null);
const internalVisible = ref(props.isVisible);


useDisableScroll(internalVisible);

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});

watch(internalVisible, async (newValue) => {
	emit('update:isVisible', newValue);
});

const handleBackdropClick = (event) => {
	if (props.backdropClosable) {
		hideOverlay();
	}
};
const hideOverlay = () => {
	internalVisible.value = false;
};


const { hasFocus, activate: activeFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(rootEl);

const handleRootElOnMounted = async () => {
	rootEl.value?.focus();
	await nextTick(); // oddly enough, we have to use nextTick in order for focus trap to work 🤔
	activeFocusTrap();
};
const handleRootElBeforeUnmount = () => {
	deactivateFocusTrap();
};


</script>


<template>
<Teleport to="body">
	<Transition
		:name="props.isEnrichNavbarOverlay ? '' : (isRTL ? 'overlay-drawer-left' : 'overlay-drawer-right')"
		appear
	>
		<div
			v-if="isVisible"
			ref="rootEl"
			class="OverlayDrawer backdrop"
			v-bind="$attrs"
			tabindex="-1"
			@keydown.esc.capture="hideOverlay"
			@vue:mounted="handleRootElOnMounted"
			@vue:beforeUnmount="handleRootElBeforeUnmount"
			@click.self="handleBackdropClick"
		>
			<div
				class="overlay-container"
				:class="{
					'enrichNavbarOverlay': props.isEnrichNavbarOverlay,
				}"
			>
				<div class="overlay-wrapper">
					<slot></slot>
				</div>
			</div>
		</div>
	</Transition>
</Teleport>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.OverlayDrawer {
	
}

.backdrop {
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: color.change(black, $alpha: 0.8);
	
	display: flex;
	justify-content: flex-end;
	transition: opacity 0.45s ease;
}

.overlay-container {
	background-color: var(--overlay-container-bg-color, white);
	width: 100%;
	max-width: 480px;
	height: 100%;
}

.overlay-container.enrichNavbarOverlay {
	max-width: 90%;
	position: absolute;
	top: 10px;
	left: 5%;
	height: 95%;
}

// animations
.overlay-drawer-right-enter-from {
	opacity: 0;
}

.overlay-drawer-right-enter-active,
.overlay-drawer-right-leave-active {
	transition: opacity 200ms;
}

.overlay-drawer-right-leave-to {
	opacity: 0;
}

.overlay-drawer-right-enter-from,
.overlay-drawer-right-leave-to {
	.overlay-container {
		transform: translateX(75%);
	}
}

.overlay-drawer-right-enter-active,
.overlay-drawer-right-leave-active {
	.overlay-container {
		transition: transform 200ms;
	}
}


// RTL animation
.overlay-drawer-left-enter-from {
	opacity: 0;
}

.overlay-drawer-left-enter-active,
.overlay-drawer-left-leave-active {
	transition: opacity 200ms;
}

.overlay-drawer-left-leave-to {
	opacity: 0;
}

.overlay-drawer-left-enter-from,
.overlay-drawer-left-leave-to {
	.overlay-container {
		transform: translateX(-75%);
	}
}

.overlay-drawer-left-enter-active,
.overlay-drawer-left-leave-active {
	.overlay-container {
		transition: transform 200ms;
	}
}

.overlay-bottom-enter-to,
.overlay-bottom-leave-from {
	.overlay-container {
		transform: translateX(0%);
	}
}


</style>
