<script>
export default {
	name: 'ColumnControl',
	editConfig: {
		emptyLabel: 'Column Control',
		isEmpty (props) {
			if (props.columnControlType) {
				return false;
			}
			return true;
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import { getCompositeComponent } from '@custom/aem-vue-editable-components';
import Parsys from '~/components/Parsys.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
const { isEditorMode } = useGlobalAEMState();

/*
*
*	variant 1 = 50:50
*	variant 2 = 70:30
*	variant 3 = 30:70
*	variant 4 = 33:33:33
*	variant 5 = 16.67:66.67:16.67
*	variant 6 = 25:25:25:25
*
*/

const props = defineProps({
	columnControlType: { type: String, default: 'variant-1' },
	enableGutter: { type: Boolean, default: false },
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	mobileVariation: { type: String, default: '' },
});

const columnParsysComputed = computed(() => {
	let numberPlaceholder = 2;

	switch (props.columnControlType) {
		case 'variant-4':
		case 'variant-5':
			numberPlaceholder = 3;
			break;
		case 'variant-6':
			numberPlaceholder = 4;
			break;
		default:
			numberPlaceholder = 2;
			break;
	}

	return Array(numberPlaceholder).fill().map((number, index) => {
		return {
			component: getCompositeComponent({
				type: 'mh/components/mh-layout-container',
				componentKey: `mh-layout-container-parsys-${index}`,
			}),
		};
	});
});

/*
*
*	Computed classname based column control type
*
*/

const variantClassnameComputed = computed(() => {
	switch (props.columnControlType) {
		case 'variant-1':
			return 'column-variant-1';

		case 'variant-2':
			return 'column-variant-2';

		case 'variant-3':
			return 'column-variant-3';

		case 'variant-4':
			return 'column-variant-4';

		case 'variant-5':
			return 'column-variant-5';

		case 'variant-6':
			return 'column-variant-6';

		default:
			break;
	}
});
</script>

<template>
<template v-if="isEditorMode && props.isHideInDesktop">
	<div class="generic-container py-5 text-center">
		<div class="py-5 bg-primary-blue-base rounded text-neutral-white-base">
			<p>Hidden in: <span v-if="props.isHideInDesktop">Desktop</span> <span v-if="props.isHideInMobile">, Mobile</span></p>
		</div>
	</div>
</template>

<template v-else>
	<div
		v-if="(isMobileViewport && !props.isHideInMobile) || (!isMobileViewport && !props.isHideInDesktop)"
		class="ColumnControl"
	>
		<div
			class="flex enrich-columnControl"
			:class="{
				'gap-x-6 lg:gap-x-4 gap-y-6 lg:gap-y-4': props.enableGutter,
				'md:flex-col-reverse': props.mobileVariation === 'stack-reverse',
				'md:flex-col': (props.mobileVariation === 'stack-retain' || props.mobileVariation === ''),
			}"
		>
			<div
				v-for="(column, index) in columnParsysComputed"
				:key="index"
				:class="variantClassnameComputed"
			>
				<Parsys
					:key="index"
					:index="index"
					:component="column.component"
					:label="`Column control layout #${index}`"
				/>
			</div>
		</div>
	</div>
</template>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ColumnControl {
	
}

html[data-site-name="enrich-portal"] {

	.ColumnControl {
		
		.enrich-columnControl {
		
			@media (#{var.$query-min-md}) and (#{var.$query-max-lg}) and (orientation: portrait) {
				@apply flex-col;
			}
		}
	}

	.column-variant-1 {
		width: 100%;

		@media #{var.$query-min-lg} {
			width: 50%;
		}

		@media (#{var.$query-min-md}) and (#{var.$query-max-lg}) and (orientation: portrait) {
			width: 100%;
		}
	}
}

.column-variant-1 {
	width: 100%;

	@media #{var.$query-min-md} {
		width: 50%;
	}
}

.column-variant-2 {
	width: 100%;

	@media #{var.$query-min-md} {
		&:first-child {
			width: 70%;
		}

		&:nth-child(2) {
			width: 30%;
		}
	}
}

.column-variant-3 {
	width: 100%;

	@media #{var.$query-min-md} {
		&:first-child {
			width: 30%;
		}

		&:nth-child(2) {
			width: 70%;
		}
	}
}

.column-variant-4 {
	width: 100%;

	@media #{var.$query-min-md} {
		width: 33.33%;
	}
}

.column-variant-5 {
	width: 100%;

	@media #{var.$query-min-md} {
		&:first-child {
			width: 16.67%;
		}

		&:nth-child(2) {
			width: 66.67%;
		}

		&:nth-child(3) {
			width: 16.67%;
		}
	}
}

.column-variant-6 {
	width: 100%;

	@media #{var.$query-min-md} {
		width: 25%;
	}
}

</style>
