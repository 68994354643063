import { useRoute, useRouter } from 'vue-router';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state';
 
export function useEnrichOverlay () {
	const route = useRoute();
	const router = useRouter();
	const { pageProperties } = useGlobalAEMState();
	const hashValue = ref(route.hash);
	const isVisible = ref(false);

	const enrichOverlayContents = ref( pageProperties.value.enrichContentPageProperties?.enrichOverlay ?? []);

	const overlayData = ref(null); // Store page data here
 
	const setOverlayID = (newHash) => {
		if (newHash) {
			router.replace({ hash: newHash });
		}
	};
 
	const removeOverlayID = () => {
		router.replace({ hash: '' });
	};
 
	const getPageDataBasedOnHash = (hash) => {
		return enrichOverlayContents.value.find((item) => item.overlayID === hash);
	};
 
	watch(
		() => route.hash,
		(newHash) => {
			hashValue.value = newHash;
			if (newHash) {
				overlayData.value = getPageDataBasedOnHash(newHash);
				isVisible.value = !!overlayData.value;
			} else {
				overlayData.value = null;
				isVisible.value = false;
			}
		},
		{ immediate: true }
	);
 

	return {
		hashValue,
		overlayData,
		isVisible,
		setOverlayID,
		removeOverlayID,
	};
}