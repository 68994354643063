<script>
export default {
	name: 'EnrichOverlays',
	inheritAttrs: false,
};
</script>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import { useEnrichOverlay } from '~/logic/composables/enrich/useEnrichOverlay';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import Anchor from '~/logic/helpers/Anchor';

const rootEl = ref(null);
const siteName = window.siteName;
const { hasFocus, activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(rootEl);

const onRootMounted = () => Anchor.activate('body');
const handleRootElOnMounted = async () => {
	rootEl.value?.focus();
	await nextTick();
	activateFocusTrap();
};
const handleRootElBeforeUnmount = () => deactivateFocusTrap();

const { overlayData, removeOverlayID, isVisible } = useEnrichOverlay();

const overlayStyles = computed(() => {
	if (isMobileViewport.value) return {};
	return { width: overlayData.value?.displayType === 'flightSearch-hotels' ? '768px' : '640px' };
});

const isFlightSearchMobile = computed(() => isMobileViewport.value && overlayData.value?.displayType === 'flightSearch-hotels'
);

const mobileOverlayContainerClass = computed(() => {
	return isMobileViewport.value && overlayData.value?.displayType === 'notification'
		? 'overlay-container'
		: 'overlay-bottom-container';
});

const showCloseButton = computed(() => !(isMobileViewport.value && overlayData.value?.displayType !== 'notification'));
const transitionName = computed(() => {
	if (isMobileViewport.value && overlayData.value?.displayType !== 'notification') return 'overlay-bottom';

	return 'overlay-drawer-right';
});
</script>

<template>
<!-- Non-mobile view overlay -->
<Teleport v-if="!isFlightSearchMobile" to="body">
	<Transition ref="rootEl" :name="transitionName" appear>
		<div
			v-if="isVisible"
			ref="rootEl"
			class="EnrichOverlays"
			:class="isMobileViewport ? 'backdrop-bottom' : 'backdrop'"
			:data-use-theme="siteName"
			tabindex="-1"
			@vue:mounted="handleRootElOnMounted"
			@vue:beforeUnmount="handleRootElBeforeUnmount"
		>
			<div
				class="h-full"
				:class="isMobileViewport ? mobileOverlayContainerClass : 'overlay-container'"
				:style="overlayStyles"
			>
				<div class="overlay-wrapper">
					<div class="overlay-line" @click="removeOverlayID"></div>
					<div
						class="drawer-header mb-3"
						:class="{ 'border-bottom': (isMobileViewport && !showCloseButton) }"
					>
						<div class="text-sm overlay-title heading-5">{{ overlayData.overlayTitle }}</div>
						<button
							v-if="showCloseButton"
							type="button"
							class="w-8 h-8 my-6 p-0 md:h-6 md:w-6 md:my-4 flex items-center justify-center"
							@click="removeOverlayID"
						>
							<icon-far-xmark class="w-2.5" />
						</button>
					</div>
					<div
						class="overlay-content p-4 flex-grow"
						:class="{ 'overflow-y-auto styled-scrollbar': overlayData.displayType !== 'notification' }"
					>
						<ExperienceFragment :xfPath="overlayData?.experienceFragmentURL" />
					</div>
				</div>
			</div>
		</div>
	</Transition>
</Teleport>

<!-- Mobile view for specific flight search -->
<Teleport v-else to="#booking-subpage-container">
	<section class="section-banner" :data-use-theme="siteName" @vue:mounted="onRootMounted">
		<div class="generic-container">
			<button class="btn-back flex items-center text-white gap-2" type="button" @click="removeOverlayID">
				<icon-far-chevron-left class="fill-current transform rtl:-rotate-180" />
				<span class="">{{ $t('Back') }}</span>
			</button>
			<div class="my-10 text-white">
				<h1 class="text-center">{{ overlayData.overlayTitle }}</h1>
			</div>
		</div>
	</section>
	<div class="top-rounded-section-breaker"></div>
	<div class="mobile-flightsearch-overlay p-3" :data-use-theme="siteName" @vue:mounted="onRootMounted">
		<ExperienceFragment :xfPath="overlayData?.experienceFragmentURL" />
	</div>
</Teleport>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.EnrichOverlays {}

.backdrop,
.backdrop-bottom {
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: color.change(black, $alpha: 0.8);
	display: flex;
	justify-content: flex-end;
	transition: opacity 0.45s ease;
}

.backdrop-bottom {
	align-items: flex-end;
	justify-content: center;
	transition: opacity 0.3s ease;
}

.overlay-bottom-container,
.overlay-container {
	background-color: white;
	width: 100%;
	overflow: hidden;
	@apply pb-4;
}

.drawer-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@apply px-6;
	border-bottom-width: 1px;
	border-bottom-color: var(--neutral-grey-base);

	&.border-bottom {
		border-bottom: none;
	}
}

.overlay-bottom-container {
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;

	.overlay-line {
		@apply pb-7 pt-4;

		&::after {
			content: '';
			width: 48px;
			height: 2px;
			display: block;
			margin: 0 auto;
			background-color: var(--neutral-grey-base);
			@apply rounded;
		}
	}
}

.overlay-wrapper {
	height: 100%;
	max-height: inherit;
	display: flex;
	flex-direction: column;
}

/* Animations */
.overlay-bottom-enter-from {
	opacity: 0;
}

.overlay-bottom-enter-active,
.overlay-bottom-leave-active {
	transition: opacity 200ms;
}

.overlay-bottom-leave-to {
	opacity: 0;
}

.overlay-bottom-enter-from,
.overlay-bottom-leave-to {
	.overlay-container {
		transform: translateY(75%);
	}
}

.overlay-bottom-enter-active,
.overlay-bottom-leave-active {
	.overlay-container {
		transition: transform 200ms;
	}
}

.overlay-bottom-enter-to,
.overlay-bottom-leave-from {
	.overlay-container {
		transform: translateY(0%);
	}
}

.overlay-drawer-right-enter-from {
	opacity: 0;
}

.overlay-drawer-right-enter-active,
.overlay-drawer-right-leave-active {
	transition: opacity 200ms;
}

.overlay-drawer-right-leave-to {
	opacity: 0;
}

.overlay-drawer-right-enter-from,
.overlay-drawer-right-leave-to {
	.overlay-container {
		transform: translateX(75%);
	}
}

.overlay-drawer-right-enter-active,
.overlay-drawer-right-leave-active {
	.overlay-container {
		transition: transform 200ms;
	}
}

.section-banner {
	--batik-bg-colour: var(--primary-blue-base);
	--batik-pattern-img: url('~/assets/batik-pattern.svg');
	background-color: var(--batik-bg-colour);
	background-image: var(--batik-pattern-img);
	background-size: 200px auto;
	background-repeat: repeat;
	height: 317px;
	overflow: hidden;
}

.btn-back {
	margin-top: var(--headerHeight);
}

.top-rounded-section-breaker {
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	--bgColor: white;
	background-color: var(--bgColor);
	height: 45px;
	margin-top: -44px;
}

.section-banner[data-use-theme="MHH"] {
	--batik-bg-colour: var(--primary-mhh-teal-base);
	--batik-pattern-img: url('~/assets/floral-pattern-short-banner.svg');
	background-size: cover;
	background-position-y: 45px;
	background-repeat: no-repeat;
}
</style>