<script>
export default {
	name: 'EnrichPortal404Layout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';


</script>


<template>
<div class="EnrichPortal404Layout flex flex-col min-h-screen" data-layout="EnrichPortal404">
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/enrich-portal/$country/$lang/site/header/master"
	/>
	<div id="content"></div>
	<main class="layout-container-center">
		<slot></slot>
	</main>
	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/cookies-notification/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.EnrichPortal404Layout {
	background-color: #f0f3f5;
	background-image: url('~/assets/enrich-songket-repeatable-x.png');
	background-repeat: repeat-x;
	background-position: center 100%;
}

:slotted([data-component="AEMPage"]) {
	
}
</style>
