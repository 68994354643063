import '~/logic/polyfill.js';
import '~/logic/detect-iOS.js';

import i18n from '~/logic/i18n.js';
import { createApp, h, resolveComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { AuthoringUtils, ModelManager, PathUtils } from '@adobe/aem-spa-page-model-manager';
import { patchRouter } from '~/logic/patchRouter.js';
import { setupFirebaseCloudMessaging } from '~/logic/firebase-cloud-messaging.js';
import { setCountryAndLocaleCookie } from '~/logic/aem/set-country-and-locale-cookie.js';

import AppHyperlink from '~/components/AppHyperlink.vue';

import customDirectives from '~/logic/custom-directives.js';

import { sleep } from '~/logic/helpers/utils.js';
import { transformObjKeyToCq } from '~/logic/helpers/aem.js';
import { doMappingLogic } from '~/components-mapping/mapping-logic.js';
doMappingLogic();

import App from '~/App.vue';
import 'virtual:windi.css';
import '~/styles/main.scss';

if (window.siteName === 'MHH') {
	import('~/styles-mhh/main.mhh.scss');
}
if (window.siteName === 'amal') {
	import('~/styles-amal/main.amal.scss');
}
if (window.siteName === 'firefly') {
	import('~/styles-firefly/main.firefly.scss');
}
if (window.siteName === 'enrich-portal') {
	import('~/styles-enrich-portal/main.enrich-portal.scss');
}

import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

import 'vite/modulepreload-polyfill'; // <-- required as our input is non-html
import { generateRoutes } from '~/logic/aem/generate-routes.js';


import { init as initBreakpoints } from '~/logic/composables/breakpoints.js';
initBreakpoints();

import dayjs from 'dayjs';

import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);

import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

import { loadDayjsLocale } from '~/logic/dayjs-locale-init.js';

loadDayjsLocale('en');



document.addEventListener('DOMContentLoaded', function onDOMContentLoaded () {
	const errorPageRoot = `${ PathUtils.getMetaPropertyValue('cq:errorpages') }/`;
	
	// TODO, find ways to avoid this call. For now, we still need this call.
	(() => {
		ModelManager.initialize({ errorPageRoot });
	})();
});

const fetchCurrentPageModel = async () => {
	const pageModelFetchUrl = document.querySelector('meta[name="mh:page-model-url"]').content;
	const fourOFourMetaTag = document.querySelector('meta[property="cq:errorpages"]');
	const pageModel404Url = fourOFourMetaTag ? `${fourOFourMetaTag.content}/404.model.json` : null;
	return (
		axios.get(pageModelFetchUrl)
			.then((response) => response.data)
			.catch((reason) => {
				console.error('fetchCurrentPageModel failed. reason = ', reason);
				if (pageModel404Url) return axios.get(pageModel404Url).then((response) => response.data);
				return null;
			})
	);
};

fetchCurrentPageModel().then((currentPageModel) => {
	console.log('currentPageModel = ', currentPageModel);
	const isEditorMode = AuthoringUtils.isInEditor();
	const rootPageUrl = document.querySelector('meta[property="cq:pagemodel_root_url"]').content;
	
	const routerAppProps = {
		cqItems: currentPageModel[':items'],
		cqItemsOrder: currentPageModel[':itemsOrder'],
		cqPath: currentPageModel[':path'],
		cqType: currentPageModel[':type'],
		injectPropsOnInit: false,

		templateName: currentPageModel['templateName'],
		language: currentPageModel['language'],
		title: currentPageModel['title'],
	};
	
	const rootPath = rootPageUrl.replace(/\.model\.json$/, '');
	const currentPath = routerAppProps.cqPath;
	const routes = generateRoutes({
		cqChildren: {
			[currentPath]: routerAppProps,
		},
		rootPath,
		currentPath,
	});
	
	console.log('routes = ', routes);
	
	const router = patchRouter(
		createRouter({
			history: createWebHistory(),
			routes: routes,
		})
	);

	/* 
		When navigating, these query params in the URL will retain (bring over to the new route).
	*/
	const QUERY_PARAMS_TO_RETAIN = [ 'wcmmode', 'vite', 'empty' ];

	function hasRetainingQueryParams (route) {
		return Object.keys(route.query).some((key) => QUERY_PARAMS_TO_RETAIN.includes(key));
	}
	function getRetainingQueryParams (route) {
		return Object.fromEntries(Object.entries(route.query).filter(([key, value]) => {
			return QUERY_PARAMS_TO_RETAIN.includes(key);
		}));
	}

	router.beforeEach((to, from, next) => {
		if (!hasRetainingQueryParams(to) && hasRetainingQueryParams(from)) {
			next({
				...to,
				query: {
					...to.query,
					...getRetainingQueryParams(from),
				},
			});
		} else {
			next();
		}
	});


	const mainApp = createApp(App, routerAppProps);

	mainApp.use(router);
	mainApp.use(i18n);
	mainApp.use(customDirectives);
	

	const rootPageModel = {
		isEditorMode,
		cqPath: rootPageUrl,
	};
	const globalAEMState = useGlobalAEMState();
	globalAEMState.rootPageModel.value = { ...transformObjKeyToCq(rootPageModel) };
	globalAEMState.pageModel.value = { ...transformObjKeyToCq(currentPageModel) };
	
	if (globalAEMState.pageProperties.value.rootLanguage !== 'en') {
		// change all underscores to hypens before passing in to dayjs, then set all to lowercase
		const dayjsLocale = globalAEMState.pageProperties.value.rootLanguage?.replaceAll('_', '-').toLowerCase();
		loadDayjsLocale(dayjsLocale);
	}

	try {
		setupFirebaseCloudMessaging();
	} catch (err) {
		// possible failure reasons: iOS does not support window.Notification
		console.error(`setupFirebaseCloudMessaging() failed. Error = `, err);
	}
	
	// set country and language to cookie
	setCountryAndLocaleCookie();

	window.mainApp = mainApp;
	window.router = router;
	
	// register global components
	mainApp.component('AppHyperlink', AppHyperlink);

	mainApp.mount('#spa-root');

});
