export default [
	/* {
		componentPath: 'aem-components/TextComponent.vue',
		resourceType: 'mh/components/text',
		editConfig: {
			emptyLabel: 'Text Component',
			isEmpty (props) {
				return !props.text;
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/ImageComponent.vue',
		resourceType: 'mh/components/image',
		editConfig: {
			emptyLabel: 'Image Component',
			isEmpty (props) {
				switch (props.imageType) {
					case 'icon':
						return !props.iconId;
					case 'image':
						return !props.fileURL;
					default:
						return !props.iconId && !props.fileURL;
				}
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/ExperienceFragment.vue',
		resourceType: 'mh/components/experience-fragment',
		editConfig: {
			emptyLabel: 'Experience Fragment',
			isEmpty (props) {
				return false;
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/EmptyGap.vue',
		resourceType: 'mh/components/empty-gap',
		editConfig: {
			emptyLabel: 'Empty Gap',
			isEmpty (props) {
				const desktopGapInt = parseInt(props.desktopVerticalGap);

				// only remove the placeholder if desktopGapInt (the height) is 24 or more
				return (!props.desktopVerticalGap && !props.mobileVerticalGap) || (desktopGapInt <= 23);
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/booking/BookingWidget.vue',
		resourceType: 'mh/components/booking/booking-widget',
		editConfig: {
			emptyLabel: 'Booking Widget',
			isEmpty (props) {
				return props.desktopProperties.length === 0;
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/FloatingWidget.vue',
		resourceType: 'mh/components/floating-widget',
		editConfig: {
			emptyLabel: 'Floating Widget',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	}, */
	{
		componentPath: 'aem-components/NewsletterSignUp.vue',
		resourceType: 'mh/components/newsletter-sign-up',
		editConfig: {
			emptyLabel: 'Newsletter Sign Up',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	/* {
		componentPath: 'aem-components/AppHeader.vue',
		resourceType: 'mh/components/header',
		editConfig: {
			emptyLabel: 'Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/AppFooter.vue',
		resourceType: 'mh/components/footer',
		editConfig: {
			emptyLabel: 'Footer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/CTAButton.vue',
		resourceType: 'mh/components/cta-button',
		editConfig: {
			emptyLabel: 'CTA Button',
			isEmpty (props) {
				return !props.ctaText;
			},
		},
	}, */
	{
		componentPath: 'aem-components/DestinationWindow.vue',
		resourceType: 'mh/components/destination-window',
		editConfig: {
			emptyLabel: 'Destination Window',
			isEmpty (props) {
				return props.windowList.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/USP.vue',
		resourceType: 'mh/components/usp',
		editConfig: {
			emptyLabel: 'USP',
			isEmpty (props) {
				return props.uspList.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/PlanYourTripTiles.vue',
		resourceType: 'mh/components/plan-your-trip-tiles',
		editConfig: {
			emptyLabel: 'Plan Your Trip Tiles',
			isEmpty (props) {
				if (props.tilesMultiList.length) return false;
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/DestinationEssentialDetails.vue',
		resourceType: 'mh/components/destination-essential-details',
		editConfig: {
			emptyLabel: 'Destination Essential Details',
			isEmpty (props) {
				return props.destinationEssentialList.length === 0;
			},
		},
	},
	// {
	// 	componentPath: 'aem-components/MobileDownloadAppNotification.vue',
	// 	resourceType: 'mh/components/mobile-download-app-notification',
	// 	editConfig: {
	// 		emptyLabel: 'Mobile Download App Notification',
	// 		isEmpty (props) {
	// 			// custom logic here
	// 			return true;
	// 		},
	// 	},
	// },
	{
		componentPath: 'aem-components/NavigationTiles.vue',
		resourceType: 'mh/components/navigation-tiles',
		editConfig: {
			emptyLabel: 'Navigation Tiles',
			isEmpty (props) {
				return props.imageMultiList.length === 0 && props.ctaMultiList.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/HtmlComponent.vue',
		resourceType: 'mh/components/html-component',
		editConfig: {
			emptyLabel: 'Html Component',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	/* {
		componentPath: 'aem-components/HeaderNotification.vue',
		resourceType: 'mh/components/header-notification',
		editConfig: {
			emptyLabel: 'Header Notification',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/Carousel.vue',
		resourceType: 'mh/components/carousel',
		editConfig: {
			emptyLabel: 'Carousel',
			isEmpty (props) {
				switch (props.carouselType) {
					case 'carousel-banner':
						if (props.totalNumOfPhBanner === 0) {
							return true;
						}
						return false;

					case 'standard-carousel':
						if (props.totalNumOfPhStandard === 0) {
							return true;
						}
						return false;
				}
				return true;
			},
		},
	}, */
	{
		componentPath: 'aem-components/SwitchTab.vue',
		resourceType: 'mh/components/switch-tab',
		editConfig: {
			emptyLabel: 'Switch Tab',
			isEmpty (props) {
				// custom logic here
				return props.tabList.length === 0;
			},
		},
	},
	/* {
		componentPath: 'aem-components/Banner.vue',
		resourceType: 'mh/components/banner',
		editConfig: {
			emptyLabel: 'Banner',
			isEmpty (props) {
				switch (props.type) {
					case 'generalHeroBanner': {
						if (props.ghDesktopImage || props.ghBackgroundStyle || props.ghGradientOverlay || props.ghBottomCurve) {
							return false;
						}
						return true;
					}
					case 'crossSellBanner': {
						if (props.csDesktopBackgroundImage || props.csKeyVisualImage || props.csBackgroundStyle || props.csGradientOverlay) {
							return false;
						}
						return true;
					}
					case 'imageTileBanner': {
						return !props.itDesktopBackgroundImage;
					}
					default: {
						return true;
					}
				}
			},
		},
	}, */
	/* {
		componentPath: 'aem-components/ColumnControl.vue',
		resourceType: 'mh/components/column-control',
		editConfig: {
			emptyLabel: 'Column Control',
			isEmpty (props) {
				if (props.columnControlType) {
					return false;
				}
				return true;
			},
		},
	}, */
	{
		componentPath: 'aem-components/DestinationsListing.vue',
		resourceType: 'mh/components/destinations-listing',
		editConfig: {
			emptyLabel: 'Destinations Listing',
			isEmpty (props) {
				return props.pageListing.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/TravelRegulationsOrAdvisory.vue',
		resourceType: 'mh/components/travel-regulations-or-advisory',
		editConfig: {
			emptyLabel: 'Travel Regulations Or Advisory',
			isEmpty (props) {
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/CrossSellTiles.vue',
		resourceType: 'mh/components/cross-sell-tiles',
		editConfig: {
			emptyLabel: 'Cross Sell Tiles',
			isEmpty (props) {
				switch (props.tileType) {
					case 'manual':
						return props.manualMultilist.length === 0;
					case 'content':
						return props.contentMultilist.length === 0;
					case 'article':
						return props.articleMultilist.length === 0;
				}
			},
		},
	},
	{
		componentPath: 'aem-components/ShowMore.vue',
		resourceType: 'mh/components/show-more',
		editConfig: {
			emptyLabel: 'Show More',
			isEmpty (props) {
				// no need to be true, as there is always a Parsys rendered
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/SearchResults.vue',
		resourceType: 'mh/components/search-results',
		editConfig: {
			emptyLabel: 'Search Results',
			isEmpty (props) {
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/AnchorTag.vue',
		resourceType: 'mh/components/anchor-tag',
		editConfig: {
			emptyLabel: 'Anchor Tag',
			isEmpty (props) {
				return false; // <-- never be true due to UI in editor mode
			},
		},
	},
	{
		componentPath: 'aem-components/booking/FlightSearch.vue',
		resourceType: 'mh/components/booking/flight-search',
		editConfig: {
			emptyLabel: 'Flight Search',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/FlightHotelSearch.vue',
		resourceType: 'mh/components/booking/flight-hotel-search',
		editConfig: {
			emptyLabel: 'Flight Hotel Search',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/ManageBooking.vue',
		resourceType: 'mh/components/booking/manage-booking',
		editConfig: {
			emptyLabel: 'Manage Booking',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/CheckIn.vue',
		resourceType: 'mh/components/booking/check-in',
		editConfig: {
			emptyLabel: 'Check In',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/FlightStatus.vue',
		resourceType: 'mh/components/booking/flight-status',
		editConfig: {
			emptyLabel: 'Flight Status',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/FlightSchedule.vue',
		resourceType: 'mh/components/booking/flight-schedule',
		editConfig: {
			emptyLabel: 'Flight Schedule',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/MHupgrade.vue',
		resourceType: 'mh/components/booking/mh-upgrade',
		editConfig: {
			emptyLabel: 'MHupgrade',
			isEmpty (props) {
				// booking widget component is always not empty
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/Accordion.vue',
		resourceType: 'mh/components/accordion',
		editConfig: {
			emptyLabel: 'Accordion',
			isEmpty (props) {
				if (props.type === 'parent' && props.children.length) {
					return false;
				}

				if (props.type === 'single' && props.accordionTitle) {
					return false;
				}

				return true;
			},
		},
	},
	/* {
		componentPath: 'aem-components/Divider.vue',
		resourceType: 'mh/components/divider',
		editConfig: {
			emptyLabel: 'Divider',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	}, */
	{
		componentPath: 'aem-components/PageProperties.vue',
		resourceType: 'mh/components/page-properties',
		editConfig: {
			emptyLabel: 'Page Properties',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/JalDataFeed.vue',
		resourceType: 'mh/components/jal-data-feed',
		editConfig: {
			emptyLabel: 'Jal Data Feed',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MHVideo.vue',
		resourceType: 'mh/components/mh-video',
		editConfig: {
			emptyLabel: 'MH Video',
			isEmpty (props) {
				if (props.videoPath || props.youtubeID) {
					return false;
				}
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MHBreadcrumb.vue',
		resourceType: 'mh/components/mh-breadcrumb',
		editConfig: {
			emptyLabel: 'MH Breadcrumb',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/InflightMenu.vue',
		resourceType: 'mh/components/inflight-menu',
		editConfig: {
			emptyLabel: 'In-Flight Menu',
			isEmpty (props) {
				
				return (props.destination.cabinClass === undefined
					&& props.destination.dataField === undefined
					&& props.destination.from === undefined
					&& props.destination.to === undefined)
					|| (props.flightNumber.cabinClass === undefined
						&& props.flightNumber.dateField === undefined
						&& props.flightNumber.flight === undefined);
			},
		},
	},
	{
		componentPath: 'aem-components/AnchorNavigation.vue',
		resourceType: 'mh/components/anchor-navigation',
		editConfig: {
			emptyLabel: 'Anchor Navigation',
			isEmpty (props) {
				if (props.anchorNavigationMultilistPojo.length) return false;
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/Steps.vue',
		resourceType: 'mh/components/steps',
		editConfig: {
			emptyLabel: 'Steps Component',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MediaCentreListing.vue',
		resourceType: 'mh/components/media-centre-listing',
		editConfig: {
			emptyLabel: 'Media Centre Listing',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MediaCentreSearch.vue',
		resourceType: 'mh/components/media-centre-search',
		editConfig: {
			emptyLabel: 'Media Centre Search',
			isEmpty (props) {
				if (props.searchPagePath) {
					return false;
				}
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MovieReelsAndSearch.vue',
		resourceType: 'mh/components/movie-reels-and-search',
		editConfig: {
			emptyLabel: 'Movie Reels And Search',
			isEmpty (props) {
				// custom logic here
				return Object.keys(props.general).length === 0 || Object.keys(props.label).length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/VoucherTile.vue',
		resourceType: 'mh/components/voucher-tile',
		editConfig: {
			emptyLabel: 'Voucher Tile',
			isEmpty (props) {
				// custom logic here
				return props.manualMultilist.length === 0 && props.contentPropAndList.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/MovieSearchResults.vue',
		resourceType: 'mh/components/movie-search-results',
		editConfig: {
			emptyLabel: 'Movie Search Results',
			isEmpty (props) {
				// custom logic here
				return Object.keys(props.general).length === 0 || Object.keys(props.labels).length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/form/FormContainer.vue',
		resourceType: 'mh/components/form/form-container',
		editConfig: {
			emptyLabel: 'Form Container',
			isEmpty (props) {
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/form/FileUpload.vue',
		resourceType: 'mh/components/form/file-upload',
		editConfig: {
			emptyLabel: 'File Upload',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/PromoListing.vue',
		resourceType: 'mh/components/promo-listing',
		editConfig: {
			emptyLabel: 'Promo Listing',
			isEmpty (props) {
				if (props.promoChildPageProperties.length > 0) {
					return false;
				}
				return true;
			},
		},
	},
	/* {
		componentPath: 'aem-components/CookiesNotification.vue',
		resourceType: 'mh/components/cookies-notification',
		editConfig: {
			emptyLabel: 'Cookies Notification',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	}, */
	{
		componentPath: 'aem-components/CountdownTimer.vue',
		resourceType: 'mh/components/countdown-timer',
		editConfig: {
			emptyLabel: 'Countdown Timer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/DatePicker.vue',
		resourceType: 'mh/components/form/date-picker',
		editConfig: {
			emptyLabel: 'Date Picker',
			isEmpty (props) {
				// custom logic here
				if (props.general?.label) return false;
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/MobilePhoneInput.vue',
		resourceType: 'mh/components/form/mobile-phone-input',
		editConfig: {
			emptyLabel: 'Mobile Phone Input',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/RadioButton.vue',
		resourceType: 'mh/components/form/radio-button',
		editConfig: {
			emptyLabel: 'Radio Button',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/Dropdown.vue',
		resourceType: 'mh/components/form/dropdown',
		editConfig: {
			emptyLabel: 'Dropdown',
			isEmpty (props) {

				return Object.keys(props.dropdown).length === 0 || Object.keys(props.general).length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/form/TextArea.vue',
		resourceType: 'mh/components/form/text-area',
		editConfig: {
			emptyLabel: 'Text Area',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/RatingSlider.vue',
		resourceType: 'mh/components/form/rating-slider',
		editConfig: {
			emptyLabel: 'Rating Slider',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/Checkbox.vue',
		resourceType: 'mh/components/form/checkbox',
		editConfig: {
			emptyLabel: 'Checkbox',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/DynamicDropdown.vue',
		resourceType: 'mh/components/form/dynamic-dropdown',
		editConfig: {
			emptyLabel: 'Dynamic Dropdown',
			isEmpty (props) {
				
				return props?.dynamicDropdown === undefined || props?.general === undefined;
			},
		},
	},
	{
		componentPath: 'aem-components/MHTable.vue',
		resourceType: 'mh/components/mh-table',
		editConfig: {
			emptyLabel: 'Table Component',
			isEmpty (props) {
				if (props.tableData) return false;
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/NumberPicker.vue',
		resourceType: 'mh/components/form/number-picker',
		editConfig: {
			emptyLabel: 'Number Picker',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/form/TextField.vue',
		resourceType: 'mh/components/form/text-field',
		editConfig: {
			emptyLabel: 'Text Field',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/BookingWidgetUrlQueryGenerator.vue',
		resourceType: 'mh/components/booking-widget-url-query-generator',
		editConfig: {
			emptyLabel: 'Booking Widget Url Query Generator',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhe/SignUpForm.vue',
		resourceType: 'mh/components/mhexplorer/signup-form',
		editConfig: {
			emptyLabel: 'MHExplorer SignUp Form',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/ContactUs/ContactUs.vue',
		resourceType: 'mh/components/contact-us-form',
		editConfig: {
			emptyLabel: 'Contact Us Form',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/ScriptExecutor.vue',
		resourceType: 'mh/components/script-executor',
		editConfig: {
			emptyLabel: 'Script Executor',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/matta/Matta2024.vue',
		resourceType: 'mh/components/matta-2024',
		editConfig: {
			emptyLabel: 'matta 2024',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MAGHeader.vue',
		resourceType: 'mh/components/mag-header',
		editConfig: {
			emptyLabel: 'Mag Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MAGFooter.vue',
		resourceType: 'mh/components/mag-footer',
		editConfig: {
			emptyLabel: 'Mag Footer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MAGBrandsCarousel.vue',
		resourceType: 'mh/components/mag-brands-carousel',
		editConfig: {
			emptyLabel: 'Mag Brands Carousel',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/MAGDownloadTiles.vue',
		resourceType: 'mh/components/mag-download-tiles',
		editConfig: {
			emptyLabel: 'Mag Download Tiles',
			isEmpty (props) {
				// custom logic here
				return props.multiList.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHHeader.vue',
		resourceType: 'mh/components/mhh/mhh-header',
		editConfig: {
			emptyLabel: 'MHH Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHFooter.vue',
		resourceType: 'mh/components/mhh/mhh-footer',
		editConfig: {
			emptyLabel: 'MHH Footer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/booking/MHHBookingWidget.vue',
		resourceType: 'mh/components/mhh/booking/mhh-booking-widget',
		editConfig: {
			emptyLabel: 'MHH Booking Widget',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHHeaderDeals.vue',
		resourceType: 'mh/components/mhh/mhh-header-deals',
		editConfig: {
			emptyLabel: 'MHH Header Deals',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/booking/MHHFlightHotelSearch.vue',
		resourceType: 'mh/components/mhh/booking/mhh-flight-hotel-search',
		editConfig: {
			emptyLabel: 'MHH Flight Hotel Search',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/booking/MHHHotelSearch.vue',
		resourceType: 'mh/components/mhh/booking/mhh-hotel-search',
		editConfig: {
			emptyLabel: 'MHH Hotel Search',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/booking/MHHTripsAndTours.vue',
		resourceType: 'mh/components/mhh/booking/mhh-trips-and-tours',
		editConfig: {
			emptyLabel: 'MHH Trips And Tours',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/booking/MHHAirportTransfer.vue',
		resourceType: 'mh/components/mhh/booking/mhh-airport-transfer',
		editConfig: {
			emptyLabel: 'MHH Airport Transfer',
			isEmpty (props) {

				return props?.airportTransfer === undefined;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHPromotionPopUp.vue',
		resourceType: 'mh/components/mhh/mhh-promotion-pop-up',
		editConfig: {
			emptyLabel: 'MHH Promotion Pop Up',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHDestinationAndTourDeals.vue',
		resourceType: 'mh/components/mhh/mhh-destination-and-tour-deals',
		editConfig: {
			emptyLabel: 'MHH Destination And Tour Deals',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHHotelDealsTiles.vue',
		resourceType: 'mh/components/mhh/mhh-hotel-deals-tiles',
		editConfig: {
			emptyLabel: 'MHH Hotel Deals Tiles',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHTripspirationCards.vue',
		resourceType: 'mh/components/mhh/mhh-tripspiration-cards',
		editConfig: {
			emptyLabel: 'MHH Tripspiration Cards',
			isEmpty (props) {
				return props.contentType === undefined ||
				(props.contentType === 'parent-page' && props.listOfChildPropfromParent.length === 0) ||
				(props.contentType === 'child-page' && props.childPageProperties.length === 0);
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHUSPIcon.vue',
		resourceType: 'mh/components/mhh/mhh-usp-icon',
		editConfig: {
			emptyLabel: 'MHH USP Icon',
			isEmpty (props) {
				// custom logic here
				return props.uspTextIconList.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/mhh/MHHBookingWidgetUrlQueryGenerator.vue',
		resourceType: 'mh/components/mhh/mhh-booking-widget-url-query-generator',
		editConfig: {
			emptyLabel: 'MHH Booking Widget Url Query Generator',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/maba/MABAHeader.vue',
		resourceType: 'mh/components/maba/maba-header',
		editConfig: {
			emptyLabel: 'Maba Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/maba/MABAFooter.vue',
		resourceType: 'mh/components/maba/maba-footer',
		editConfig: {
			emptyLabel: 'Maba Footer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/maba/MABAImageOverflowTile.vue',
		resourceType: 'mh/components/maba/maba-image-overflow-tile',
		editConfig: {
			emptyLabel: 'MABA Image Overflow Tile',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/AmalFooter.vue',
		resourceType: 'mh/components/amal/amal-footer',
		editConfig: {
			emptyLabel: 'Amal Footer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/AmalHeader.vue',
		resourceType: 'mh/components/amal/amal-header',
		editConfig: {
			emptyLabel: 'Amal Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/booking/AmalBookingWidget.vue',
		resourceType: 'mh/components/amal/booking/amal-booking-widget',
		editConfig: {
			emptyLabel: 'Amal Booking Widget',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/booking/AmalFlightSearch.vue',
		resourceType: 'mh/components/amal/booking/amal-flight-search',
		editConfig: {
			emptyLabel: 'Amal Flight Search',
			isEmpty (props) {
				// custom logic here
				return props.bookFlight === null || props.multiCity === null || props.bonusSideTrip === null;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/AmalMallTiles.vue',
		resourceType: 'mh/components/amal/amal-mall-tiles',
		editConfig: {
			emptyLabel: 'Amal Mall Tiles',
			isEmpty (props) {
				// custom logic here
				return props.amalMallTilesMultilist.length < 1;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/booking/AmalFlightSchedule.vue',
		resourceType: 'mh/components/amal/booking/amal-flight-schedule',
		editConfig: {
			emptyLabel: 'Amal Flight Schedule',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/booking/AmalUmrahPackages.vue',
		resourceType: 'mh/components/amal/booking/amal-umrah-packages',
		editConfig: {
			emptyLabel: 'Amal Umrah Packages',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/amal/booking/AmalFlightHotelSearch.vue',
		resourceType: 'mh/components/amal/booking/amal-flight-hotel-search',
		editConfig: {
			emptyLabel: 'Amal Flight Hotel Search',
			isEmpty (props) {
				// custom logic here
				return props.flightHotelSearch?.titleLabel === undefined && props.hoteltab?.titleLabel === undefined;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/FireflyHeader.vue',
		resourceType: 'mh/components/firefly/firefly-header',
		editConfig: {
			emptyLabel: 'Firefly Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/FireflyFooter.vue',
		resourceType: 'mh/components/firefly/firefly-footer',
		editConfig: {
			emptyLabel: 'Firefly Footer',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/FireflyInformationTiles.vue',
		resourceType: 'mh/components/firefly/firefly-information-tiles',
		editConfig: {
			emptyLabel: 'Firefly Information Tiles',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/booking/FireflyBookingWidget.vue',
		resourceType: 'mh/components/firefly/booking/firefly-booking-widget',
		editConfig: {
			emptyLabel: 'Firefly Booking Widget',
			isEmpty (props) {
				// custom logic here
				return props.desktopProperties.length === 0;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/FireflyDealsTiles.vue',
		resourceType: 'mh/components/firefly/firefly-deals-tiles',
		editConfig: {
			emptyLabel: 'Firefly Deals Tiles',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/FireflyTravelInfoTiles.vue',
		resourceType: 'mh/components/firefly/firefly-travel-info-tiles',
		editConfig: {
			emptyLabel: 'Firefly Travel Info Tiles',
			isEmpty (props) {
				// custom logic here
				return (
					!props.label &&
					!props.description &&
					props.travelInfoTiles.length === 0
				);
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/booking/FireflyFlightSearch.vue',
		resourceType: 'mh/components/firefly/booking/firefly-flight-search',
		editConfig: {
			emptyLabel: 'Firefly Flight Search',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/booking/FireflyAirCargo.vue',
		resourceType: 'mh/components/firefly/booking/firefly-air-cargo',
		editConfig: {
			emptyLabel: 'Firefly Air Cargo',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/booking/FireflyManageBooking.vue',
		resourceType: 'mh/components/firefly/booking/firefly-manage-booking',
		editConfig: {
			emptyLabel: 'Firefly Manage Booking',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/booking/FireflyNeighbourFreeSeat.vue',
		resourceType: 'mh/components/firefly/booking/firefly-neighbour-free-seat',
		editConfig: {
			emptyLabel: 'Firefly Neighbour Free Seat',
			isEmpty (props) {
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/firefly/booking/FireflyCheckIn.vue',
		resourceType: 'mh/components/firefly/booking/firefly-check-in',
		editConfig: {
			emptyLabel: 'Firefly Check In',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/FlysiswaForm.vue',
		resourceType: 'mh/components/flysiswa/flysiswa-form',
		editConfig: {
			emptyLabel: 'Flysiswa Form Sign Up',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichHeader.vue',
		resourceType: 'mh/components/enrich-portal/enrich-header',
		editConfig: {
			emptyLabel: 'Enrich Header',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichMembershipSummary.vue',
		resourceType: 'mh/components/enrich-portal/enrich-membership-summary',
		editConfig: {
			emptyLabel: 'Enrich Membership Summary',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichFlyPass.vue',
		resourceType: 'mh/components/enrich-portal/enrich-fly-pass',
		editConfig: {
			emptyLabel: 'Enrich Fly Pass',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichPointsActivity.vue',
		resourceType: 'mh/components/enrich-portal/enrich-points-activity',
		editConfig: {
			emptyLabel: 'Enrich Points Activity',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichUpcomingTrips.vue',
		resourceType: 'mh/components/enrich-portal/enrich-upcoming-trips',
		editConfig: {
			emptyLabel: 'Enrich Upcoming Trips',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichCrossSellTiles.vue',
		resourceType: 'mh/components/enrich-portal/enrich-cross-sell-tiles',
		editConfig: {
			emptyLabel: 'Enrich Cross Sell Tiles',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichCarouselTiles.vue',
		resourceType: 'mh/components/enrich-portal/enrich-carousel-tiles',
		editConfig: {
			emptyLabel: 'Enrich Carousel Tiles',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichNavigationBar.vue',
		resourceType: 'mh/components/enrich-portal/enrich-navigation-bar',
		editConfig: {
			emptyLabel: 'Enrich Navigation Bar',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichMidTierBooster.vue',
		resourceType: 'mh/components/enrich-portal/enrich-mid-tier-booster',
		editConfig: {
			emptyLabel: 'Enrich Mid-Tier Booster',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhe/PostLoginHandler.vue',
		resourceType: 'mh/components/mhexplorer/mhexplorer-student-login',
		editConfig: {
			emptyLabel: 'MHE post login handler',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhe/StudentInfoCard.vue',
		resourceType: 'mh/components/mhexplorer/mhexplorer-card-details',
		editConfig: {
			emptyLabel: 'MHE Student Card Details',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/mhe/BookingWidget/MHEFlightSearch.vue',
		resourceType: 'mh/components/mhexplorer/booking/mhe-flight-search',
		editConfig: {
			emptyLabel: 'MHE Flight Search',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},
	{
		componentPath: 'aem-components/TravelConfirmationLetter.vue',
		resourceType: 'mh/components/travel-confirmation-letter',
		editConfig: {
			emptyLabel: 'Travel Confirmation Letter',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},


	{
		componentPath: 'aem-components/enrich-portal/EnrichStatusBenefits.vue',
		resourceType: 'mh/components/enrich-portal/enrich-status-benefits',
		editConfig: {
			emptyLabel: 'Enrich Status Benefits',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/enrich-portal/EnrichNotifications.vue',
		resourceType: 'mh/components/enrich-portal/enrich-notifications',
		editConfig: {
			emptyLabel: 'Enrich Notifications',
			isEmpty (props) {
				// custom logic here
				return true;
			},
		},
	},
	{
		componentPath: 'aem-components/booking/FlightDisruption.vue',
		resourceType: 'mh/components/booking/flight-disruption',
		editConfig: {
			emptyLabel: 'Flight Disruption',
			isEmpty (props) {
				// custom logic here
				return false;
			},
		},
	},

];

