<script>
export default {
	name: 'ONDPicker',
};
</script>

<script setup>
import AirportPicker from '~/components/BookingWidget/AirportPicker.vue';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useONDList } from '~/logic/composables/booking-widget/useONDList.js';
import AppSpinner from '~/components/AppSpinner.vue';
import { useGeolocation } from '@vueuse/core';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { MHScrollToElement } from '~/logic/helpers/dom.js';

const props = defineProps({
	nameFrom: { type: String, required: true },
	nameTo: { type: String, required: true },
	
	modelValueFrom: { type: [String, Object], default: null },
	modelValueTo: { type: [String, Object], default: null },
	
	labelTextFrom: { type: String, default: i18nGlobal.t('From') },
	labelTextTo: { type: String, default: i18nGlobal.t('To') },

	requiredFrom: { type: Boolean, default: false },
	requiredTo: { type: Boolean, default: false },
	
	requiredErrorMsgFrom: { type: String, default: i18nGlobal.t('This field is required') },
	requiredErrorMsgTo: { type: String, default: i18nGlobal.t('This field is required') },
	
	/* 
		----------------------
		When props.useOptionsFromProp true, all the following props will be in effect.
	*/
	useOptionsFromProp: { type: Boolean, default: false },
	optionsFrom: { type: Object, default: null },
	optionsTo: { type: Object, default: null },
	isLoadingFrom: { type: Boolean, default: false },
	isLoadingTo: { type: Boolean, default: false },
	// ----------------------
	
	noResultsText: { type: String, default: null },
	requestGeolocation: { type: Boolean, default: true },
	
	ondListIdentifier: { type: String, default: '' },
	// emitOptionChange: { type: Boolean, default: false },

	/* Overwrite group label options for From/To options */
	groupLabelsOverwrite: { type: Object, default: null },
	
	/*
		~~~~~ Specific Origin & Destination for CUG Enhancement ~~~~~
        props.specificOriginList & props.specificDestinationList are a long list string with 3 characters airportCode separated by comma.
        E.g.: "SZB,KUL,ABZ"
        This is configured by author in AEM, to show just the specific "FROM & TO airport list" (origin & destination). All other exluded airportCode will be hidden.
        The 3 possible configuration-scenarios are as below:
		1️⃣) only specificOriginList: will fetch 👉 `${OND-base-url}?type=base&ori=SZB,KUL,ABZ`, will return destination list which was filtered on server side;
		2️⃣) only specificDestinationList: will fetch 👉 `${OND-base-url}?type=base&des=SZB,KUL,ABZ`, will return origin list which was filtered on server side;
		3️⃣) both: 👉 same as scenario (i) above, with an additional FE-filter based on the passed props.specificDestinationList value.
    */
	specificOriginList: { type: String, default: '' },
	specificDestinationList: { type: String, default: '' },

	hideSpecificOriginList: { type: String, default: '' },
	hideSpecificDestinationList: { type: String, default: '' },

	restrictedDestinationList: { type: String, default: '' },
	enableONDLocaleTranslation: { type: Boolean, default: false },

	additionalONDFilter: { type: String, default: '' },
});

const emit = defineEmits([
	'update:modelValueFrom',
	'update:modelValueTo',
	// 'update:optionsFrom',
	// 'update:optionsTo',
]);

const internalValueFrom = useInternalValue('modelValueFrom');
const internalValueTo = useInternalValue('modelValueTo');
const siteName = window.siteName;
const { pageModel } = useGlobalAEMState();
let siteLocale = pageModel.value?.language.replace('-', '_').toUpperCase();
siteLocale = (!props.enableONDLocaleTranslation || siteLocale === 'EN') ? null : siteLocale;

const specificOriginArray = props.specificOriginList ? props.specificOriginList.replace(/\s/g, '').split(',') : [];
const specificDestinationArray = props.specificDestinationList ? props.specificDestinationList.replace(/\s/g, '').split(',') : [];

const hideSpecificOriginArray = props.hideSpecificOriginList ? props.hideSpecificOriginList.replace(/\s/g, '').split(',') : [];
const hideSpecificDestinationArray = props.hideSpecificDestinationList ? props.hideSpecificDestinationList.replace(/\s/g, '').split(',') : [];

const restrictedDestinationArray = props.restrictedDestinationList ? props.restrictedDestinationList.replace(/\s/g, '').split(',') : [];

const handleSwapValues = () => {
	swapLocations();

	/*
		~~~~~ Specific Origin & Destination for CUG Enhancement ~~~~~
		Do airport origin check after swapping.
		Clear origin airport value if swapped origin airport in not listed in 
		props.specificOriginList, props.specificDestinationList, props.hideSpecificOriginList, props.hideSpecificDestinationList and props.restrictedDestinationList.
    */

	if (!internalValueFrom.value && !internalValueTo.value) return;

	// When specific-origin is configured OR
	// When hide-specific-origin is configured
	if (internalValueFrom.value) {
		if ((specificOriginArray.length && !specificOriginArray.includes(internalValueFrom.value?.value))
			|| (hideSpecificOriginArray.length && hideSpecificOriginArray.includes(internalValueFrom.value?.value))
		) {
			internalValueFrom.value = null;
		}
	}

	if (internalValueTo.value) {
		// When specific-origin is configured OR
		// When hide-specific-destination is configured
		// When restricted-destination is configured
		if ((specificDestinationArray.length && !specificDestinationArray.includes(internalValueTo.value?.value))
			|| (hideSpecificDestinationArray.length && hideSpecificDestinationArray.includes(internalValueTo.value?.value))
			|| (restrictedDestinationArray.length && !restrictedDestinationArray.includes(internalValueTo.value?.value))
		) {
			internalValueTo.value = null;
		}
	}


};

const isJDTPortal = ref(false);
const airportPickerFrom = ref(null);
const airportPickerTo = ref(null);

const handleFromUpdateValue = (newValue) => {
	if (newValue && !isMobileViewport.value) airportPickerTo.value?.focus();
};


const slots = useSlots();

const fromPickerSlots = computed(() => {
	return Object.fromEntries(Object.keys(slots).filter((slotName) => {
		if (slotName.startsWith('from-picker-')) return true;
		return false;
	}).map((slotName) => {
		const newSlotName = slotName.replace('from-picker-', '');
		return [newSlotName, slots[slotName]];
	}));
});
const toPickerSlots = computed(() => {
	return Object.fromEntries(Object.keys(slots).filter((slotName) => {
		if (slotName.startsWith('to-picker-')) return true;
		return false;
	}).map((slotName) => {
		const newSlotName = slotName.replace('to-picker-', '');
		return [newSlotName, slots[slotName]];
	}));
});

const {
	optionsFrom,
	optionsTo,
	
	isLoadingFrom,
	isLoadingTo,
	
	setUserGeolocation,
	swapLocations,
	
	updateFromValue,
	updateToValue,
} = props.useOptionsFromProp ? {} : useONDList({
	fromValueRef: internalValueFrom,
	toValueRef: internalValueTo,
	listIdentifier: toRef(props, 'ondListIdentifier'),
	translationLocale: siteLocale,
	// emitOptionChange: toRef(props, 'emitOptionChange'),
});

const computedGroupLabelsFromOptions = computed(() => {
	/* 
		Refer preserved structure map to groupId in useONDList.js, 
		GROUP_ID and GROUP_ID_TO_LABEL_MAP contains default i18n group labels
	*/
	const newGroupLabelsOptions = optionsFrom.value.map((fromItem) => {
		return {
			groupId: fromItem.groupId,
			label: props.groupLabelsOverwrite[fromItem.groupId] || fromItem.label,
			options: fromItem.options,
		};
	});

	return newGroupLabelsOptions;
});

const computedGroupLabelsToOptions = computed(() => {
	/* 
		Refer preserved structure map to groupId in useONDList.js, 
		GROUP_ID and GROUP_ID_TO_LABEL_MAP contains default i18n group labels
	*/
	const newGroupLabelsOptions = optionsTo.value.map((toItem) => {
		return {
			groupId: toItem.groupId,
			label: props.groupLabelsOverwrite[toItem.groupId] || toItem.label,
			options: toItem.options,
		};
	});

	return newGroupLabelsOptions;
});

const computedIsLoadingFrom = computed(() => {
	props.isLoadingFrom; // eslint-disable-line
	isLoadingFrom?.value; // eslint-disable-line
	if (props.useOptionsFromProp) return props.isLoadingFrom;
	return isLoadingFrom.value;
});
const computedIsLoadingTo = computed(() => {
	props.isLoadingTo; // eslint-disable-line
	isLoadingTo?.value; // eslint-disable-line
	if (props.useOptionsFromProp) return props.isLoadingTo;
	return isLoadingTo.value;
});

/*
	~~~~~ Specific Origin & Destination for CUG Enhancement ~~~~~
	This part is used to initialize the airport destination based on
	the passed props.specificOriginList & props.specificDestinationList.
*/
const specificOriginOptionsTo = ref(null);
const specificDestinationOptionsFrom = ref(null);

const hideSpecificOriginOptionsFrom = ref(null);
const hideSpecificDestinationOptionsTo = ref(null);

// Update origin options list, when specific-origin is configured
if (props.specificOriginList) {
	const {
		optionsTo: specificOriginToList,
		updateFromValue: updateSpecificOriginFromValue,
	} = useONDList({
		fromValueRef: ref(props.specificOriginList.replace(/\s/g, '')),
		toValueRef: internalValueTo,
		listIdentifier: toRef(props, 'ondListIdentifier'),
	});

	updateSpecificOriginFromValue().then(() => {
		specificOriginOptionsTo.value = specificOriginToList.value;

		// When both specific-origin & specific-destination are configured
		if (props.specificDestinationList) {
			specificOriginOptionsTo.value = specificOriginOptionsTo.value.map((item) => {
				return {
					...item,
					options: item.options.filter((option) => {
						// return true;
						return specificDestinationArray.some((airportCode) => {
							return option.airportCode === airportCode;
						});
					}),
				};
			});
		}
	});
}

// Update destination options list, when specific-destination is configured
if (props.specificDestinationList && !props.specificOriginList) {
	const {
		optionsFrom: specificDestinationFromList,
		updateToValue: updateSpecificDestinationToValue,
	} = useONDList({
		toValueRef: ref(props.specificDestinationList.replace(/\s/g, '')),
		fromValueRef: internalValueFrom,
		listIdentifier: toRef(props, 'ondListIdentifier'),
	});

	updateSpecificDestinationToValue().then(() => {
		specificDestinationOptionsFrom.value = specificDestinationFromList.value;
	});
}

// Update origin options list, when hide-specific-origin is configured
if (hideSpecificOriginArray.length) {
	const {
		optionsFrom: fromList,
		updateFromValue: updateSpecificOriginFromValue,
	} = useONDList({
		fromValueRef: internalValueFrom,
		toValueRef: internalValueTo,
		listIdentifier: toRef(props, 'ondListIdentifier'),
	});

	updateSpecificOriginFromValue().then(() => {
		hideSpecificOriginOptionsFrom.value = fromList.value.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					return !hideSpecificOriginArray.some((airportCode) => {
						return option.airportCode === airportCode;
					});
				}),
			};
		});
	});
}

// Update destination options list, when hide-specific-destination is configured
if (hideSpecificDestinationArray.length) {
	const {
		optionsTo: toList,
		updateToValue: updateSpecificDestinationToValue,
	} = useONDList({
		fromValueRef: internalValueFrom,
		toValueRef: internalValueTo,
		listIdentifier: toRef(props, 'ondListIdentifier'),
	});

	updateSpecificDestinationToValue().then(() => {
		hideSpecificDestinationOptionsTo.value = toList.value.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					return !hideSpecificDestinationArray.some((airportCode) => {
						return option.airportCode === airportCode;
					});
				}),
			};
		});
	});
}

const generateOptionListing = ref(false);
const computedOptionsFrom = computed(() => {
	props.useOptionsFromProp; // eslint-disable-line
	props.optionsFrom; // eslint-disable-line
	optionsFrom?.value; // eslint-disable-line
	if (!generateOptionListing.value && siteName === 'MH') return null;
	if (computedIsLoadingFrom.value) return null;
	if (props.useOptionsFromProp) return props.optionsFrom;

	let finalListFrom = optionsFrom.value;

	// If prop groupLabelsOverwrite not null or configured, return the options with overwritten group labels
	if (props.groupLabelsOverwrite !== null) {
		finalListFrom = computedGroupLabelsFromOptions.value;
	}

	/*
		~~~~~ Specific Origin & Destination for CUG Enhancement ~~~~~
		👉 Apply when specific-origin is configured, to filter the origin airport list
		Below Code is also handling the restricted origin list configued for MHH flight hotel search.
	*/
	if (props.specificOriginList) {
		finalListFrom = finalListFrom.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					// return true;
					return specificOriginArray.some((airportCode) => {
						return option.airportCode === airportCode;
					});
				}),
			};
		});
	}

	// For scenario where only specific-destination are configured:
	// 👉 Reset the origin list back to initialized-state when the input for airport destination is blank.
	if (props.specificDestinationList && !props.specificOriginList) {
		if (specificDestinationOptionsFrom.value && !internalValueTo.value) {
			finalListFrom = specificDestinationOptionsFrom.value;
		}
	}

	if (hideSpecificOriginArray.length) {
		finalListFrom = hideSpecificOriginOptionsFrom.value;
	}

	if (isJDTPortal.value) {
		finalListFrom = finalListFrom.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					return option.isRedemption;
				}),
			};
		});
	}

	finalListFrom = filterAdditionalONDFilter(props.additionalONDFilter, finalListFrom);

	// Removing options-grouping-label if the groupList is 0
	finalListFrom = finalListFrom.filter((groupList) => {
		return groupList.options.length;
	});

	return finalListFrom;
});

const computedOptionsTo = computed(() => {
	props.useOptionsFromProp; // eslint-disable-line
	props.optionsTo; // eslint-disable-line
	optionsTo?.value; // eslint-disable-line
	if (!generateOptionListing.value && siteName === 'MH') return null;
	if (computedIsLoadingTo.value) return null;
	if (props.useOptionsFromProp) return props.optionsTo;

	let finalListTo = optionsTo.value;

	// If prop groupLabelsOverwrite not null or configured, return the options with overwritten group labels
	if (props.groupLabelsOverwrite !== null) {
		finalListTo = computedGroupLabelsToOptions.value;
	}

	/*
		~~~~~ Specific Origin & Destination for CUG Enhancement ~~~~~
	*/
	if (props.specificOriginList) {
		// ------ TCS enhancement for MHH site_START ------
		// ~ Restricted  Destination for CUG Enhancement (This only applies for MHH flight + hotel) ~
		// Apply when Restricted  Destination from list(destination) is configured.
		if (props.restrictedDestinationList) {
			const restrictedDestinationList = props.restrictedDestinationList.replace(/\s/g, '').split(',');

			finalListTo = finalListTo.map((item) => {
				return {
					...item,
					options: item.options.filter((option) => {
						return restrictedDestinationList.some((airportCode) => {
							return option.airportCode === airportCode;
						});
					}),
				};
			});
		}
		// ------ TCS enhancement for MHH site_END ------
	
		// For scenario where both specific-origin & specific-destination are configured:
		// 👉 After fetching specific-origin-API, this will filter the API-return-data (Destination-List) based on props.specificDestinationList
		if (props.specificDestinationList) {
			finalListTo = finalListTo.map((item) => {
				return {
					...item,
					options: item.options.filter((option) => {
						// return true;
						return specificDestinationArray.some((airportCode) => {
							return option.airportCode === airportCode;
						});
					}),
				};
			});
		}

		// 👉 Reset the destination list back to initialized-state when the input for airport origin is blank.
		if (specificOriginOptionsTo.value && !internalValueFrom.value) {
			finalListTo = specificOriginOptionsTo.value;
		}
	}

	// 👉 To filter OND-Destination-List based on props.specificDestinationList
	if (props.specificDestinationList && !props.specificOriginList) {
		finalListTo = finalListTo.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					// return true;
					return specificDestinationArray.some((airportCode) => {
						return option.airportCode === airportCode;
					});
				}),
			};
		});
	}

	if (hideSpecificDestinationArray.length) {
		finalListTo = hideSpecificDestinationOptionsTo.value;
	}

	if (isJDTPortal.value) {
		finalListTo = finalListTo.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					return option.isRedemption;
				}),
			};
		});
	}

	finalListTo = filterAdditionalONDFilter(props.additionalONDFilter, finalListTo);

	// Removing options-grouping-label if the groupList is 0
	finalListTo = finalListTo.filter((groupList) => {
		return groupList.options.length;
	});

	return finalListTo;
});

function filterAdditionalONDFilter (filterKey, data) {
	if (!filterKey || (filterKey && filterKey === 'none')) return data;

	let filteredData = [...data];

	switch (filterKey) {
		case 'hidePartnerCodeshare':
			filteredData = filteredData.filter((groupList) => {
				return groupList.groupId !== 'CODESHARE';
			});
			break;
		case 'onlyShowMHH':
			filteredData = filteredData.map((item) => {
				return {
					...item,
					options: item.options.filter((option) => {
						return option.isMhHoliday;
					}),
				};
			});
			break;
		case 'onlyShowNewsletter':
			filteredData = filteredData.map((item) => {
				return {
					...item,
					options: item.options.filter((option) => {
						return option.isNewsletter;
					}),
				};
			});
			break;
			
		default:
			break;
	}

	return filteredData;
}


let userGeolocation = null;

const handleOnOpen = () => {
	// prompt to generate OND-option-list
	generateOptionListing.value = true;

	if (userGeolocation !== null) {
		console.log('Disabling geolocation. Reason: geolocation-data has been shared');
		return;
	}

	if (props.requestGeolocation && userGeolocation === null) {
		userGeolocation = useGeolocation();
		setUserGeolocation(userGeolocation);
		console.log('Enabling geolocation');
		return;
	}

	console.log('Disabling geolocation. Reason: disabled in author configuration');

	// Implement auto-scrolling and focus on booking-widget when user interacting with the AirportPicker & CalendarPicker.
	// Only works when both conditions are met:
	// 1.) desktop-view & 2.) booking-widget without sticky-styling
	const isBookingWidget_Sticky = document.querySelector('.BookingWidget .SwitchTabContent')?.classList.contains('sticked');
	if (!isMobileViewport.value && !isBookingWidget_Sticky) {
		MHScrollToElement(document.querySelector('.ONDPicker'), { additionalTopOffset: 160 });
	}
};


// TODO: ❗❗❗ REMOVE AFTER DONE OND ❗❗❗
(() => {
	const currentRouteQuery = window.router.currentRoute.value.query;
	if (('debug' in currentRouteQuery) && !document.querySelector('#DEBUG_STYLES')) {
		const styleTag = window.document.createElement('style');
		styleTag.id = 'DEBUG_STYLES';
		styleTag.innerHTML = `
			.hidden-debug-info { display: block !important; }
			.ONDPicker .AirportPicker .multiselect-main { --ms-max-height: 500px !important; }
			.ONDPicker .AirportPicker .multiselect-options { max-height: 500px !important; }
		`;
		window.document.body.appendChild(styleTag);
	}
})();


/* ❗ Note:
------------
	Any time modelValueXXX is updated externally (i.e. change of value not coming from this component's child <AirportPicker>),
	the corresponding 'updateXXXValue' must be called. This is not done automatically. See <EditSearchBST> for example.
	Hence, we are exposing the function call here.
*/
defineExpose({
	updateFromValue,
	updateToValue,
});

const updateModelValueHandler = (newValue) => {
	handleFromUpdateValue(newValue);
	updateFromValue();
};

onMounted(() => {
	const pathName = window.location.pathname;
	isJDTPortal.value = pathName.endsWith('jdt-portal.html');
});
</script>


<template>
<div class="ONDPicker" :data-use-theme="siteName">
	<p class="hidden-debug-info">🐞 {{ props.ondListIdentifier }}</p>
	<div class="inner-wrapper">
		<div class="picker-wrapper picker-wrapper-from">
			<AirportPicker
				ref="airportPickerFrom"
				v-model="internalValueFrom"
				class="AirportPicker-from"
				:name="props.nameFrom"
				:options="computedOptionsFrom"
				:labelText="props.labelTextFrom"
				:canClear="true"
				:showOptionLocationIcon="true"
				:required="props.requiredFrom"
				:requiredErrorMsg="props.requiredErrorMsgFrom"
				:noResultsText="props.noResultsText"
				:attrs="{
					'readOnly': isLoadingFrom,
				}"
				:aria-label="$t('Airport of departure')"
				variant="booking-widget"
				@update:modelValue="updateModelValueHandler"
				@open="handleOnOpen"
			>
				<template v-for="(_, slotName) in fromPickerSlots" #[slotName]="slotData">
					<slot :name="`from-picker-${slotName}`" v-bind="slotData"></slot>
				</template>
				
				<template v-if="computedIsLoadingFrom" #nooptions>
					<div class="multiselect-options cursor-default">
						<div class="py-4">
							<AppSpinner class="mx-auto" />
						</div>
					</div>
				</template>
			</AirportPicker>
		</div>
		<div class="sr-only" role="alert">
			{{ $t('Selected departure airport is {airport}', { airport: internalValueFrom?.displayValue ?? internalValueFrom?.value ?? internalValueFrom }) }}
		</div>
		<button
			v-button-press
			class="btn-switch btn-generic mx-2 flex"
			type="button"
			:aria-label="$t('Swap locations')"
			@click="handleSwapValues"
		>
			<icon-far-arrow-right-arrow-left class="fill-white m-auto" />
		</button>
		<div class="picker-wrapper picker-wrapper-to">
			<AirportPicker
				ref="airportPickerTo"
				v-model="internalValueTo"
				:name="props.nameTo"
				:options="computedOptionsTo"
				:labelText="props.labelTextTo"
				:canClear="true"
				:showOptionLocationIcon="true"
				:required="props.requiredTo"
				:requiredErrorMsg="props.requiredErrorMsgTo"
				:noResultsText="props.noResultsText"
				:attrs="{
					'readOnly': isLoadingTo,
				}"
				variant="booking-widget"
				:aria-label="$t('Airport of arrival')"
				@open="handleOnOpen"
				@update:modelValue="updateToValue();"
			>
				<template v-for="(_, slotName) in toPickerSlots" #[slotName]="slotData">
					<slot :name="`to-picker-${slotName}`" v-bind="slotData"></slot>
				</template>
				
				<template v-if="computedIsLoadingTo" #nooptions>
					<div class="multiselect-options cursor-default">
						<div class="py-4">
							<AppSpinner class="mx-auto" />
						</div>
					</div>
				</template>
			</AirportPicker>
		</div>
		<div class="sr-only" role="alert">
			{{ $t('Selected arrival airport is {airport}', { airport: internalValueTo?.displayValue ?? internalValueTo?.value ?? internalValueTo }) }}
		</div>
	</div>
	
	<div class="error-msg-container leading-tight flex text-semantic-red-base text-sm md:flex-col">
		<div class="flex-1 mt-1 w-[min-content] empty:mt-0 md:w-full">
			<span
				v-if="(
					airportPickerFrom?.hiddenTextField?.hasValidationError &&
					airportPickerFrom?.hiddenTextField?.meta.touched
				)"
				v-html-sanitize="airportPickerFrom?.hiddenTextField.errors[0]"
			>
			</span>
		</div>
		<div class="w-$gapWidth flex-none md:hidden"></div>
		<div class="flex-1 mt-1 w-[min-content] empty:mt-0 md:w-full">
			<span
				v-if="(
					airportPickerTo?.hiddenTextField?.hasValidationError &&
					airportPickerTo?.hiddenTextField?.meta.touched
				)"
				v-html-sanitize="airportPickerTo?.hiddenTextField.errors[0]"
			>
			</span>
		</div>
	</div>
	
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ONDPicker {
	--gapWidth: 56px;
	
	:deep(.AirportPicker) {
		.multiselect-main {
			--ms-border-color: transparent;
			--ms-bg: transparent;
			
			&:hover {
				--ms-border-color: var(--neutral-grey-base);
				--ms-bg: #{color.change(white, $alpha: 0.35)};
			}
			&:focus, &:focus-within {
				--ms-border-color: var(--primary-blue-base);
				--ms-bg: white;
			}
		}
		
		&.has-validation-error {
			.multiselect-main {
				--ms-border-color: var(--semantic-red-light);
				
				&:hover {
					--ms-border-color: #{var.$semantic-red-base-50-opacity};
				}
				&:focus, &:focus-within {
					--ms-border-color: var(--semantic-red-base);
				}
			}
		}
		.multiselect-dropdown {
			width: calc(200% + var(--gapWidth));
		}
		.dropdown-caret {
			display: none;
		}
		.error-msg-container {
			display: none;
		}
	}
	
	@media #{var.$query-max-md} {
		:deep(.AirportPicker) {
			.multiselect-main {
				--ms-border-color: var(--neutral-grey-light);
				--ms-bg: var(--neutral-grey-ultralight);
				
				&:focus, &:focus-within {
					--ms-border-color: var(--primary-blue-base);
					--ms-bg: white;
				}
			}
		}
	}
}

.inner-wrapper {
	border-radius: 12px;
	background-color: var(--neutral-grey-ultralight);
	box-shadow: 0 0 0 1.5px var(--neutral-grey-light) inset;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	
	@media #{var.$query-max-md} {
		background-color: transparent;
		box-shadow: none;
	}
}

.picker-wrapper {
	flex: 1 1 0;
	
	@media #{var.$query-max-md} {
		flex: 0 0 100%;
	}
}

.picker-wrapper-from {
	@media #{var.$query-max-md} {
		@apply mb-4;
	}
}

.picker-wrapper-to {
	--dropdownTranslateX: calc(-50% - (var(--gapWidth) / 2) - 4px);

	html[dir="rtl"] & {
		--dropdownTranslateX: calc(50% + (var(--gapWidth) / 2) + 4px);
	}
}

.btn-switch {
	width: 40px;
	height: 40px;
	flex: 0 0 40px;
	border-radius: 100%;
	align-self: center;
	
	@media #{var.$query-max-md} {
		position: absolute;
		z-index: 10;
		right: 28px;
		transform: rotate(90deg);
	}
}

[data-use-theme="MHH"] {
	&.ONDPicker :deep(.AirportPicker) .multiselect-main {
		&:focus,
		&:focus-within {
			--ms-border-color: var(--primary-mhh-teal-base);
		}

		.multiselect-option {

			&.is-pointed {
				color: var(--primary-mhh-teal-base);
			}
		}
	}
}

.ONDPicker[data-use-theme="firefly"] {
	
	:deep(.AirportPicker) {
		.multiselect-main {
			&:focus, &:focus-within {
				--ms-border-color: var(--primary-firefly-orange-base);
				--ms-bg: white;
			}
		}
	}
	.btn-switch {
		background-color: var(--primary-firefly-orange-base);
	}
}

</style>
