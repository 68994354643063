<script>
export default {
	name: 'MobileDrawerHeader',
};
</script>

<script setup>
import { useEventBus } from '@vueuse/core';
import defaultLogoImg from '~/assets/logo-mh-white.svg';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';

const props = defineProps({
	logoImgSrc: { type: String, default: defaultLogoImg },
	logoImgAlt: { type: String, default: 'Malaysia Airlines Logo' },
	logoOpenInNewTab: { type: Boolean, default: false },
	logoIsHideMobileLogoImg: { type: Boolean, default: false },
	drawerIsHideMobileSearch: { type: Boolean, default: false },
	drawerIsHideAccountIcon: { type: Boolean, default: false },
	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'click:header-x',
]);

const globalHeaderBus = useEventBus('main-global-header');

const dispatchHeaderEvent = (eventName) => {
	globalHeaderBus.emit(eventName, true);
};

</script>

<template>
<div class="MobileDrawerHeader border-b px-6 py-3 flex">
	<div
		v-if="!props.logoIsHideMobileLogoImg"
		class="logo-container flex items-center"
	>
		<img
			class="logo-primary-img h-auto min-h-[22px] w-[98px]"
			:src="props.logoImgSrc ? props.logoImgSrc : defaultLogoImg"
			:alt="props.logoImgAlt"
			:target="props.logoOpenInNewTab ? '_blank' : null"
		/>
	</div>

	<div class="ml-auto rtl:ml-0 rtl:mr-auto flex gap-x-3">
		<button
			v-if="isMobileViewport && !props.drawerIsHideMobileSearch"
			v-aa="[
				{
					clickName: $t('Search'),
					clickComponentType: 'Button',
					componentName: 'AppHeader',
					componentID: props.componentIdAA,
				},
				{
					name: $t('Search'),
					type: 'other',
				},
			]"
			class="btn-side-interaction"
			type="button"
			style="--focus-visible-outline-offset: -2px;"
			:aria-label="$t('Search')"
			@click="dispatchHeaderEvent('trigger-search'); $emit('click:header-x');"
		>
			<icon-far-magnifying-glass class="fill-current" />
		</button>

		<button
			v-if="isMobileViewport && !props.drawerIsHideAccountIcon"
			v-aa="[
				{
					clickName: $t('Account'),
					clickComponentType: 'Button',
					componentName: 'AppHeader',
					componentID: props.componentIdAA,
				},
				{
					name: $t('Account'),
					type: 'other',
				},
			]"
			class="btn-side-interaction"
			type="button"
			style="--focus-visible-outline-offset: -2px;"
			:aria-label="$t('Account')"
			@click="dispatchHeaderEvent('trigger-login'); $emit('click:header-x');"
		>
			<icon-far-user class="fill-current" />
		</button>

		<button class="h-full" type="button" @click="$emit('click:header-x')">
			<icon-far-xmark class="fill-current" />
		</button>
	</div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MobileDrawerHeader {
	border-bottom-color: #556084;
}

</style>
