<script>
export default {
	name: 'MobileCountrySelector',
};
</script>

<script setup>
import OverlayDrawer from '~/components/OverlayDrawer.vue';
import MobileDrawerHeader from '~/components/AppHeader/MobileDrawerHeader.vue';
import { setCountryAndLocaleCookie } from '~/logic/aem/set-country-and-locale-cookie.js';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	mobileSelectedCountryLabelSelector: { type: String, default: '' },
	mobileSelectedLanguageLabelSelector: { type: String, default: '' },
	mobileAllCountriesLabelSelector: { type: String, default: '' },
	mobileAllCountriesAndLanguages: { type: Array, default: () => [] },
	mobileSelectedCountryAndLanguage: { type: Object, default: () => ({}) },
	countryLanguageRedirectBaseUrl: { type: String, default: '' },
	
	headerIsHideMobileSearch: { type: Boolean, default: false },
	headerIsHideAccountIcon: { type: Boolean, default: false },
	headerLogoImgSrc: { type: String, default: '' },
	headerLogoImgAlt: { type: String, default: '' },
	headerLogoOpenInNewTab: { type: Boolean, default: false },
	headerLogoIsHideMobileLogoImg: { type: Boolean, default: false },
	mobileAvailableLanguagesSelector: { type: Array, default: () => [] },
});
const emit = defineEmits([
	'update:isVisible',
	'click:btn-back',
	'click:header-x',
]);


const handleLanguageLinkClick = (country, language) => {
	// console.log(`handleLanguageLinkClick: ${country}, ${language}`);
	setCountryAndLocaleCookie({ country, language });
};

const getRedirectLink = ({ country, lang } = {}) => {
	const path = window.location.pathname;
	return `${props.countryLanguageRedirectBaseUrl}?country=${country}&lang=${lang}&path=${path}`;
};

const siteName = window.siteName;

</script>

<template>
<OverlayDrawer
	:isVisible="props.isVisible"
	class="text-white"
	:style="{
		'--overlay-container-bg-color': siteName === 'firefly' ? 'var(--neutral-firefly-white-base)' : 'var(--secondary-blue-extradark)',
	}"
	:data-use-theme="siteName"
	@update:is-visible="$emit('update:isVisible', $event)"
>
	<div class="MobileCountrySelector overflow-y-auto text-white">
		<MobileDrawerHeader
			class="sticky top-0 bg-$overlay-container-bg-color z-10"
			:drawerIsHideMobileSearch="props.headerIsHideMobileSearch"
			:drawerIsHideAccountIcon="props.headerIsHideAccountIcon"
			:logoImgSrc="props.headerLogoImgSrc"
			:logoImgAlt="props.headerLogoImgAlt"
			:logoOpenInNewTab="props.headerLogoOpenInNewTab"
			:logoIsHideMobileLogoImg="props.headerLogoIsHideMobileLogoImg"
			@click:header-x="$emit('click:header-x', false)"
		/>

		<div class="btn-back-container w-full sticky top-[52px] px-6 bg-$overlay-container-bg-color z-10">
			<button type="button" class="flex items-center py-3 px-0" @click="$emit('click:btn-back')">
				<icon-far-chevron-left class="fill-current mr-3.5 rtl:mr-0 rtl:ml-3.5 transform rtl:-rotate-180" />
				<span class="">{{ $t('Back') }}</span>
			</button>
		</div>
		<div class="listing-container px-6 mt-4">
			<div v-if="siteName !== 'amal' && siteName !== 'enrich-portal'" class="typo-category-title mb-4">
				{{ props.mobileSelectedCountryLabelSelector }}
			</div>
			<div v-if="siteName == 'enrich-portal'" class="typo-category-title mb-4">
				{{ props.mobileSelectedLanguageLabelSelector }}
			</div>

			<nav v-if="siteName !== 'amal' && siteName !== 'enrich-portal'" aria-label="Selected Country/Language">
				<div class="mb-3 label-country">{{ props.mobileSelectedCountryAndLanguage.country }}</div>
				<div class="flex items-start">
					<template
						v-for="(selectedCountryLanguageItem, selectedCountryLanguageIndex) in props.mobileSelectedCountryAndLanguage.languages"
						:key="selectedCountryLanguageIndex"
					>
						<AppHyperlink
							:href="getRedirectLink({ country: selectedCountryLanguageItem.countryCode, lang: selectedCountryLanguageItem.language })"
							class="link"
							:aria-label="`${props.mobileSelectedCountryAndLanguage.country} ${selectedCountryLanguageItem.languageTitle}`"
							:forceATag="true"
							:addHtmlExtension="false"
							@click="handleLanguageLinkClick(selectedCountryLanguageItem.countryCode, selectedCountryLanguageItem.language)"
						>
							{{ selectedCountryLanguageItem.languageTitle }}
						</AppHyperlink>
						<span
							v-if="( props.mobileSelectedCountryAndLanguage.languages.length - 1) !== selectedCountryLanguageIndex"
							class="dot mx-2"
						>•</span>
					</template>
				</div>
			</nav>

			<nav v-if="siteName === 'enrich-portal'" aria-label="Selected Language">
				<div class="flex items-start">
					<template
						v-for="(selectedLanguage, selectedLanguageIndex) in props.mobileAvailableLanguagesSelector"
						:key="selectedLanguageIndex"
					>
						<AppHyperlink
							:href="selectedLanguage.path"
							class="link"
							:aria-label="`${selectedLanguage.languageTitle}`"
							:forceATag="true"
							:addHtmlExtension="false"
							@click="handleLanguageLinkClick(selectedLanguage.countryCode, selectedLanguage.language)"
						>
							{{ selectedLanguage.languageTitle }}
						</AppHyperlink>
						<span
							v-if="( props.mobileAvailableLanguagesSelector.length - 1) !== selectedLanguageIndex"
							class="dot mx-2"
						>•</span>
					</template>
				</div>
			</nav>

			<hr
				v-if="props.mobileSelectedCountryLabelSelector && props.mobileSelectedCountryAndLanguage && props.mobileSelectedCountryAndLanguage.country"
				class="separator my-6"
			/>
			<div v-if="props.mobileAllCountriesLabelSelector" class="typo-category-title mb-4">{{ props.mobileAllCountriesLabelSelector }}</div>
			<nav v-if="siteName !== 'enrich-portal'" class="country-language-list-wrapper" :aria-label="props.mobileAllCountriesLabelSelector">
				<div
					v-for="(allCountriesLanguages, allCountriesLanguagesIndex) in props.mobileAllCountriesAndLanguages"
					:key="allCountriesLanguagesIndex"
					class="grid row-span-2 rows-subgrid pb-8"
				>
					<div class="text-base leading-5 pb-3 label-country" style="">{{ allCountriesLanguages.pageTitle }}</div>
					<div class="flex items-start">
						<template
							v-for="(countryLanguageItem, countryLanguageIndex) in allCountriesLanguages.languages"
							:key="countryLanguageIndex"
						>
							<AppHyperlink
								:href="countryLanguageItem.path"
								class="link"
								:aria-label="`${allCountriesLanguages.pageTitle} ${countryLanguageItem.languageTitle}`"
								:forceATag="true"
								:addHtmlExtension="false"
								@click="handleLanguageLinkClick(countryLanguageItem.countryCode, countryLanguageItem.language)"
							>
								{{ countryLanguageItem.languageTitle }}
							</AppHyperlink>
							<span
								v-if="(allCountriesLanguages.languages.length - 1) !== countryLanguageIndex"
								class="dot mx-2"
							>•</span>
						</template>
					</div>
				</div>
			</nav>
		</div>
	</div>
</OverlayDrawer>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MobileCountrySelector {
	max-height: 100vh;
	max-height: 100svh;
}

.separator {
	background-color: #303751;
}
.menu-item {
	border-bottom: 1px solid #303751;
}
.link {
	color: var(--primary-blue-extralight);
}

</style>
